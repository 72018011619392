import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { getData, postData } from "../apiHelper";

function CompleteChallenges() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleView = (item) => {
    setSelectedItem(item);
    openModal();
  };
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlechallengeslist = async () => {
    try {
      const response = await getData("admin/completed-challenges");
      console.log("get challenges successful!", response);
      setChallenges(response.completedChallenges);
    } catch (error) {
      console.error("challenges error:", error);
    }
  };

  const approvedChallenge = async () => {
    var id = await selectedItem.insult_id;
    var challengeId = await selectedItem.challengeId;
    var unique_id = await selectedItem.challengeId;
    console.log(id)
    const response = await postData('admin/approved-challenge', { insult_id: id, challengeId: challengeId, unique_id:unique_id});
       console.log("response", response);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    handlechallengeslist();
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <>
      <div
        className={`position-relative bg-white d-flex p-0 ${
          sidebarOpen ? "sidebar-open" : ""
        }`}
      >
        {showLoader && (
          <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <Sidebar sidebarOpen={sidebarOpen} />
        <div className={`content ${sidebarOpen ? "open" : ""}`}>
          <Navbar toggleSidebar={toggleSidebar} />
          <>
            <div className="container-fluid p-lg-5 p-md-5 p-2">
              {/* Add tabs for different status filters */}

              <div className="row">
                <div className="col-lg-12">
                  <table className="table mt-5 table-space table-borderless">
                    <thead className="py-2">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">First Party Name</th>
                        <th scope="col">Second Party Name</th>
                        <th scope="col">Challenge Date</th>
                        <th scope="col">Reasion</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status </th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {challenges.map((item) => (
                        <tr key={item.insult_id}>
                          <td>{item.insult_id}</td>
                          <td>{item.username}</td>
                          <td>{item.opponent_username}</td>
                          <td>
                            {new Date(item.created_at).toLocaleDateString()}
                          </td>
                          <td>{`${item.reasion}`}</td>
                          <td>{`₹ ${item.amount}`}</td>
                          <td>{`${item.status}`}</td>
                          <td>
                            <div>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleView(item)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#BA1E1E" }}
              >
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ color: "#fff" }}
                >
                  You want to win someone over
                </h5>
                <button
                  style={{
                    backgroundColor: "none",
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                   <div className="image_box">
                   <img  src={`https://backend.progame.co.in/uploads/screenshort/${selectedItem.imagefilename}`}
                      alt={selectedItem.imagefilename}
                      className="img-fluid w-100"
                    />
                   </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <h4 className="pb-2">First party</h4>
                    <p>Name: {selectedItem.username}</p>
                    <p>Challenge Reasion: {selectedItem.amount}</p>
                    <p>Amount: {selectedItem.amount}</p>
                    <p>Status: {selectedItem.status}</p>
                  </div>

                  <div className="col-md-6">
                    <div>
                      <h4 className="pb-2">Second Party</h4>
                      <p>Name: {selectedItem.opponent_username}</p>
                      <p>Challenge Reasion: {selectedItem.amount}</p>
                      <p>Amount: {selectedItem.amount}</p>
                      <p>Status: {selectedItem.status}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-space-between">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-dismiss="modal"
                  onClick={approvedChallenge}
                >
                  Approved challenge
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { CompleteChallenges };
