import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { getData } from "./apiHelper";
import UserTable from "./components/UserTable";
function Dashboardhome() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [users, setUsers] = useState([]);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleGetuserslist = async () => {
    try {
        const response = await getData('admin/users');
        console.log('Login successful!', response);
        setUsers(response.users)
    } catch (error) {
        console.error('Login error:', error);
    }
};

  useEffect(() => {
    handleGetuserslist()
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000);
    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div>
      <div className={`position-relative bg-white d-flex p-0 ${
          sidebarOpen ? "sidebar-open" : ""
        }`}
      >
        {showLoader && (
          <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <Sidebar sidebarOpen={sidebarOpen} />
        <div className={`content ${sidebarOpen ? "open" : ""}`}>
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="container-fluid p-lg-5 p-md-5 p-3">
            

            <div class="row ">
              <div class="col-lg-12 col-md-12">
                <UserTable users={users}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboardhome;
