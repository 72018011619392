import React from 'react';
import "../App.css";

function EventProducts() {

    return (


        <>
            <div className="container-fluid back_color">
                <div className='row bg-white p-3'>
                    <div className='col-sm-12'>
                        <h4>Approved Products for Event</h4>
                        <div className='mt-3'>
                            <p>Never forget which products you need to bering to an event.<br />Choose manually or sent Customer a product Toue  and have them do the woprk</p>
                        </div>
                    </div>
                </div>
              
                <div className='mt-4 bg-white p-3'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h4>Photo Booth</h4>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end mt-2'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-h fa-rotate-by fa-xl icon_size"></i></a>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-10'>
                            <div className="row g-lg-3 g-sm-3 g-2  mt-1">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-v fa-xl  icon_size"></i></a>
                        </div>
                    </div>
                </div>
                <div className='mt-4 bg-white p-3'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h4>Photo Booth</h4>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end mt-2'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-h fa-rotate-by fa-xl icon_size"></i></a>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-10'>
                            <div className="row g-lg-3 g-sm-3 g-2  mt-1">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-v fa-xl  icon_size"></i></a>
                        </div>
                    </div>
                </div>
                <div className='mt-4 bg-white p-3'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h4>Photo Booth</h4>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end mt-2'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-h fa-rotate-by fa-xl icon_size"></i></a>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-10'>
                            <div className="row g-lg-3 g-sm-3 g-2  mt-1">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-v fa-xl  icon_size"></i></a>
                        </div>
                    </div>
                </div>
                <div className='mt-4 bg-white p-3'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h4>Photo Booth</h4>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-end mt-2'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-h fa-rotate-by fa-xl icon_size"></i></a>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-10'>
                            <div className="row g-lg-3 g-sm-3 g-2  mt-1">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-4">
                                    <div className="upload-btn-wrapper p-0">
                                        <img src="img/file-upload.png" className="img-fluid" alt="" />
                                        <input className="input-upload-btn" type="file" name="myfile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2 d-flex justify-content-end mt-5 pt-3'>
                            <a className='text-dark' href='#'><i className="fas fa-ellipsis-v fa-xl  icon_size"></i></a>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='d-flex'>
                    <button type="button" className="btn btn-primary rounded-circle"><h4 className='text-white fs-4'>+</h4></button>
                    <h4 className='ms-3 mt-2'>Add Product Category</h4>
                    </div>
                </div>

            </div>
        </>
    );
}

export { EventProducts }; /* ========================= We had to remove default. */
