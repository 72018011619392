// Importing the createBrowserRouter function from react-router-dom library to handle routing in our React application
import { createBrowserRouter } from "react-router-dom";
// Important Components so i can connect the Template Button to the Template Page

// ============== ******* Importing the Components that will be used as pages for the different routes ******* ==============

// When you import a component and it does not have the {} around it, it means that it is the default export of the file.
import Dashboard from "../Dashboardhome";

// Importing the components that will be used as pages for the different routes
import { Templates } from "../Templates";
import { Products } from "../Products";
import { AddProduct } from "../Add-product";
import {MonthAvailability} from "../components/MonthAvaibility";
import {DayCalendar} from "../components/DayCalendar";
import MonthCalendar  from "../components/MonthCalendar";
import {MichaeMeyers}  from "../components/MichaeMeyers";
import Login from "../Login";
import { CompleteChallenges } from "../components/completeChallenges";
import { Paymentsystem } from "../components/paymentsystem";
import { Addamountwallet } from "../components/Addamountwallet";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login></Login>,
  },
  {
    path: "/Login",
    element: <Login></Login>,
  },
  {
    path: "/Dashboard",
    element: <Dashboard></Dashboard>,
  },
  {
    path: "/paymentsystem",
    element: <Paymentsystem></Paymentsystem>,
  },

  {
    path: "/add-amount-to-wallet",
    element: <Addamountwallet></Addamountwallet>,
  },
  {
    path: "/templates",
    element: <Templates></Templates>,
  },

  {
    path: "/completechallenges",
    element: <CompleteChallenges></CompleteChallenges>,
  },
  {
    path: "/products",
    element: <Products></Products>,
  },

  {
    path: "/add-product",
    element: <AddProduct></AddProduct>,
  },

   // Calendar routes
   {
    path: "/monthavaibility",
    element: <MonthAvailability></MonthAvailability>,
  },

  {
    path: "/daycalendar",
    element: <DayCalendar></DayCalendar>,
  },

  {
    path: "/monthcalendar",
    element: <MonthCalendar></MonthCalendar>,
  },
  {
    path: "/michaeMeyers",
    element: <MichaeMeyers></MichaeMeyers>,
  },
]);

