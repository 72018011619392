import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { getData, postData } from "../apiHelper";

function Paymentsystem() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [listedPayments, setListedPaymets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [newPayment, setNewPayment] = useState({
    name: "",
    upiid: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment({ ...newPayment, [name]: value });
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleView = () => {
    openModal();
  };

  const handleOnoff = async (event) => {
    console.log("event==============", event);
    try {
      const response = await postData("admin/toggle-payment-gateway", {
        gatewayId: event.id,
      });
      console.log("New payment added:", response);
      getPaymentList();
    } catch (error) {
      console.error("Error adding new payment:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setShowModal(false);
      const response = await postData("admin/add-payment", newPayment);
      console.log("New payment added:", response);
      getPaymentList();
    } catch (error) {
      console.error("Error adding new payment:", error);
    }
  };

  const getPaymentList = async () => {
    try {
      const response = await getData("admin/payment-list");
      console.log("response", response.paymentslist);
      setListedPaymets(response.paymentslist);
    } catch (error) {
      console.error("Error fetching payment list:", error);
    }
  };

  useEffect(() => {
    getPaymentList();
    setShowLoader(false);
  }, []);

  return (
    <>
      <div
        className={`position-relative bg-white d-flex p-0 ${
          sidebarOpen ? "sidebar-open" : ""
        }`}
      >
        {showLoader && (
          <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <Sidebar sidebarOpen={sidebarOpen} />
        <div className={`content ${sidebarOpen ? "open" : ""}`}>
          <Navbar toggleSidebar={toggleSidebar} />
          <>
            <div className="container-fluid p-lg-5 p-md-5 p-2">
              <div className="row">
                <div className="col-lg-12">
                  <table className="table mt-5 table-space table-borderless">
                    <thead className="py-2">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">UPI ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">On/Off</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listedPayments.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id }</td>
                          <td>{item.upiid}</td>
                          <td>{item.name}</td>
                          <td>{item.is_active === 1 ? "On" : "Off"}</td>
                          <td className="mx-auto text-center">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`switch-${item.id}`}
                                checked={item.is_active === 1}
                                onChange={() => handleOnoff(item)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`switch-${item.id}`}
                              ></label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 text-end">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                   onClick={handleView}>
                    Add payments ID
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#BA1E1E" }}
              >
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ color: "#fff" }}
                >
                  You want to win someone over
                </h5>
                <button
                  style={{
                    backgroundColor: "none",
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div >
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={newPayment.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="upiid" className="form-label">
                        upiid
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="upiid"
                          name="upiid"
                          value={newPayment.upiid}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                        Add Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export { Paymentsystem };
