import React, { useState } from "react";

const AddEventDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [options, setOptions] = useState([]);
  const [showInputField, setShowInputField] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleAddButtonClick = () => {
    setShowInputField(true);
  };

  const handleCreateButtonClick = () => {
    if (newCategory.trim() !== "") {
      setOptions([...options, newCategory]);
      setNewCategory("");
      setShowInputField(false);
    }
  };

  return (
    <div className="accordion">
      <div className={`accordion-header ${isOpen ? "active" : ""}`} onClick={toggleDropdown}>
        <span className="selected-option">{selectedOption}</span>
        <span className="icon overflow-hidden">
          <i className="fa-solid fa-caret-down" />
        </span>
      </div>
      <div className={`accordion-content ${isOpen ? "active" : ""}`}>
        {options.map((option, index) => (
          <div key={index} className="option" onClick={() => handleOptionClick(option)}>
            {option}
          </div>
        ))}
        {showInputField ? (
          <div className="input-with-button d-flex">
            <input
              type="text"
              placeholder=" New Category.."
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
            <button id="createButton" onClick={handleCreateButtonClick}>
              Create
            </button>
          </div>
        ) : (
          <button id="button" onClick={handleAddButtonClick}>
           + Add Category
          </button>
        )}
      </div>
    </div>
  );
};

export default AddEventDropdown;
