import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { getData } from "../apiHelper";

function MonthAvailability() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [statusFilter, setStatusFilter] = useState("pending"); // Default filter

  const [showLoader, setShowLoader] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlechallengeslist = async () => {
    try {
      const response = await getData("admin/get-challenges");
      console.log("Login successful!", response);
      setChallenges(response.challenges);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleView = (id) => {
    // Logic to view specific data entry based on id
  };

  const handleDelete = (id) => {
    // Logic to delete specific data entry based on id
  };

  useEffect(() => {
    handlechallengeslist();
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(loaderTimeout);
  }, []);

  // Filter challenges based on status
  const filteredChallenges = challenges.filter(
    (challenge) => challenge.status === statusFilter
  );

  return (
    <div
      className={`position-relative bg-white d-flex p-0 ${
        sidebarOpen ? "sidebar-open" : ""
      }`}
    >
      {showLoader && (
        <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Sidebar sidebarOpen={sidebarOpen} />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <>
          <div className="container-fluid p-lg-5 p-md-5 p-2">
            {/* Add tabs for different status filters */}
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    statusFilter === "pending" && "active"
                  }`}
                  onClick={() => setStatusFilter("pending")}
                >
                  Pending
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    statusFilter === "accepted" && "active"
                  }`}
                  onClick={() => setStatusFilter("accepted")}
                >
                  Accepted
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    statusFilter === "cancelled" && "active"
                  }`}
                  onClick={() => setStatusFilter("canceled")}
                >
                  Cancelled
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    statusFilter === "declined" && "active"
                  }`}
                  onClick={() => setStatusFilter("declined")}
                >
                  Declined
                </button>
              </li>
            </ul>
            <div className="row">
              <div className="col-lg-12">
                <table className="table mt-5 table-space table-borderless">
                  <thead className="py-2">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Challenge Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredChallenges.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString()}
                        </td>
                         <td>{`₹ ${item.amount}`}</td>
                         <td>{`${item.status}`}</td>
                        <td>
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleView(item.id)}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger mx-2"
                              onClick={() => handleDelete(item.id)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                            <button type="button" className="btn btn-danger">
                              <i className="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export { MonthAvailability };
