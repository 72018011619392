import React from "react";
import { NavLink } from "react-router-dom";
const Sidebar = ({ sidebarOpen }) => (
  <div className={`sidebar pe-0 pt-4 back_color ${sidebarOpen ? "open" : ""}`}>
    <nav className="navbar back_color ">
      <a href="Dashboard-home.js" className="navbar-brand mx-2 mb-3">
        <img class="w-75 " src="img/logo.png" />
      </a>
      <div className="navbar-nav w-100 ">
        {" "}
        <NavLink
          to={"/Dashboard"}
          className="nav-item nav-link text-uppercase"
          activeClassName="active"
        >
          <i className="fa fa-home me-3" /> Dashboard
        </NavLink>
        <NavLink
          to={"/monthavaibility"}
          className="nav-item nav-link text-uppercase"
          activeClassName="active"
        >

          <i className="fas fa-user me-3" /> All Challenges
        </NavLink>

        <NavLink
          to={"/completechallenges"}
          className="nav-item nav-link text-uppercase"
          activeClassName="active"
        > <i className="fas fa-user me-3" /> Complete Challenges
        </NavLink>


        <NavLink
          to={"/paymentsystem"}
          className="nav-item nav-link text-uppercase"
          activeClassName="active"
        > <i className="fas fa-user me-3" /> Payment-System
        </NavLink>

        <NavLink
          to={"/add-amount-to-wallet"}
          className="nav-item nav-link text-uppercase"
          activeClassName="active"
        > <i className="fas fa-user me-3" /> add-Payment-wallet
        </NavLink>


        <NavLink
          to={"/templates"}
          className="nav-item nav-link text-uppercase"
          activeClassName="active"
        >
          <i className="fas fa-user me-3" /> All User
        </NavLink>
        <a href="#" className="nav-item nav-link text-uppercase">
          <i className="fa-solid fa-images me-3" />
          Gallery
        </a>
        <NavLink to={"/customertour"} className="nav-item nav-link text-uppercase">
          <i className="fa-solid fa-images me-3" />
          Customer Tour
        </NavLink>
      </div>
    </nav>
  </div>
);

export default Sidebar;
