import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import axios from 'axios';
function Login() {
    let navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        const data = {
            username: username,
            password: password
        };
        try {
            const response = await axios.post('https://backend.progame.co.in/admin/login', data);
            console.log('login', response.data);
            const jsonResponse = response.data;
            if (jsonResponse.status === 'success') {
                localStorage.setItem('token', jsonResponse.token);
                navigate('/Dashboard');
            } else {
                alert(jsonResponse.message);
            }
        } catch (error) {
            console.error('Error:', error);
       
        }
    }


        
    return (
        <div style={{ height: '100vh', backgroundColor:'#BA1E1E' }}>
        <div className="container  justify-content-center align-items-center" style={{ height: '100vh', paddingTop:'10%', }}>
          <div className='row'>
            <div className='col-lg-4 col-md-4 col-12 mx-auto'>
            <div className="card p-3">
                <div className="text-center p-2">
                        <img className="w-25" src="img/logo.png"></img>
                        <h4>Admin Login</h4>
                </div>
                <div className="card-body">
                    <div >
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input type="text" className="form-control" id="username" placeholder='username' value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" className="form-control" id="password" placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button type="submit" className="btn w-100 " onClick={handleSubmit} style={{ backgroundColor:'#BA1E1E', color:'#fff'}}>Login</button>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        </div>
    );
}

export default Login;

