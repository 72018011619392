import React from 'react';

function UserTable({ users }) {
  return (
    <table className="table mt-5 table-space table-borderless">
      <thead className='py-2'>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">CUSTOMERID</th>
          <th scope="col">NAME</th>
          <th scope="col">EMAIL</th>
          <th scope="col">MOBILE NO</th>
          <th scope="col">VOLET</th>
          <th scope="col">JOIN DATE</th>
          <th scope="col">STATUS</th>
          <th scope="col">ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <tr key={user.user_id}>
            <td>{user.user_id}</td>
            <td>{user.unique_id}</td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.mobile}</td>
            <td>₹ 123</td> {/* Placeholder value for 'VOLET' */}
            <td>{user.created_at}</td> {/* Assuming 'created_at' contains the join date */}
            <td>{user.status}</td>
            <td>
              <div>
                <button type="button" className="btn btn-danger"><i className="fas fa-eye"></i></button>
                <button type="button" className="btn btn-danger mx-2"><i className="fas fa-trash"></i></button>
                <button type="button" className="btn btn-danger"><i className="fas fa-edit"></i></button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UserTable;
