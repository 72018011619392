import React, { useState, useEffect } from 'react';
import "../App.css";

function EventOverview() {
    return (


        <>
            <div className="container-fluid p-lg-5 p-md-5 p-2 bg-white">
                <div className='row'>
                    <div className='col-lg-10 col-md-10 col-12 mx-auto'>
                        <h4>Event Info</h4>
                        <div className="row mt-3">
                            <div className="col">
                                <label for="inputCustomer" className="form-label fs-dark">Customer Name </label>
                                <input type="text" className="form-control h-75" id="inputCustomer" placeholder="Customer Name" aria-label="First name" />
                            </div>
                            <div className="col">
                                <label className="form-label">Event Names</label>
                                <input type="text" className="form-control h-75" placeholder="Last name" aria-label="Event Names" />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col">
                                <label for="inputCustomer" className="form-label fs-dark"> Email Address</label>
                                <input type="email" className="form-control h-75" id="inputCustomer" placeholder="Email Address" aria-label="Email Address" />
                            </div>
                            <div className="col">
                                <label for="inputCustomer" className="form-label fs-dark"> Event Date</label>
                                <div className="row g-3">
                                    <div className="col-sm-5">
                                        <input type="date" className="form-control" placeholder="City" aria-label="City" />
                                    </div>
                                    <div className='col-sm-7'>
                                        <div className='row'>
                                            <div className="col-sm-6">
                                                <input type="time" className="form-control" placeholder="State" aria-label="State" />
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="time" className="form-control" placeholder="Zip" aria-label="Zip" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col">
                                <label for="inputCustomer" className="form-label fs-dark"> Email Address</label>
                                <input type="email" className="form-control h-75" id="inputCustomer" placeholder="Email Address" aria-label="Email Address" />
                            </div>
                            <div className="col">
                                <label className="form-label">Phone Number</label>
                                <input type="number" className="form-control h-75" placeholder="Phone Number" aria-label="Event Names" />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-sm-5">
                                <label className="form-label">Event Type</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option selected></option>
                                    <option value="1">Wedding</option>
                                    <option value="2">Sweet 16</option>
                                    <option value="3">Birdhday</option>
                                    <option value="4">Party</option>
                                </select>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4>Event Attendant</h4>
                            <p>Select how many attendants will be at event and select how many reminders
                                you want them to receive about the  event .Each reminder will be appied all attendants added</p>
                            <div className="row mt-2 d-flex justify-content-between">
                                <div className="col-sm-5">
                                    <label className="form-label">Installer/Attendant </label>
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected></option>
                                        <option value="1">1 Hour Before</option>
                                        <option value="2">1 Day Before</option>
                                        <option value="3">3 Days Before</option>
                                        <option value="4">7 Days Before</option>
                                    </select>
                                </div>
                                <div className="col-sm-5">
                                    <label className="form-label">Reminder </label>
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected></option>
                                        <option value="1">1 Hour Before</option>
                                        <option value="2">1 Day Before</option>
                                        <option value="3">3 Days Before</option>
                                        <option value="4">7 Days Before</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="mb-3">
                                <label for="exampleFormControlTextarea1" className="form-label">Event Notes</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}

export { EventOverview }; /* ========================= We had to remove default. */
