import React, { useState, useEffect } from 'react';
import "../App.css";

function Payments() {
    return (


        <>
            <div className="container-fluid p-lg-5 p-md-5  bg-white">
                <div className='row'>
                    <div className='col-lg-11 col-md-11 mx-auto'>
                        <h4> Proposal-2 Hour Event</h4>
                        <h5 className='mt-3'>Payment Schedule</h5>
                        <div className="mt-5">
                            <table className="table table-responsive-lg">
                                <thead>
                                    <tr>
                                        <th scope="col" className='amou_nt'>AMOUNT </th>
                                        <th scope="col" className='amou_nt'>DUE DATE</th>
                                        <th scope="col" className='amou_nt'>PAYMENT DATE</th>
                                        <th scope="col" className='amou_nt'>PAYMENT ID</th>
                                        <th scope="col" className='amou_nt'>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">$200</th>
                                        <td>Aug 15, 2023</td>
                                        <td></td>
                                        <td>#00001-001</td>
                                        <td>OVERDUE</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">$200</th>
                                        <td>Sep 15, 2023</td>
                                        <td></td>
                                        <td>#00001-001</td>
                                        <td>UPCOMING </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-end py-4 '>
                            <button type="button" className="btn btn-primary rounded-pill px-4 py-2"> VIEW INVOICE</button>
                        </div>


                    </div>
                </div>


            </div>
        </>
    );
}

export { Payments }; /* ========================= We had to remove default. */
