import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AddEventDropdown from './AddEventDropdown';

const CalendarGrid = ({ initialYear, initialMonth }) => {

    const [currentDate, setCurrentDate] = useState(new Date(initialYear, initialMonth));
    const [selectedDates, setSelectedDates] = useState([]);
    // dropdown state
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [newCategory, setNewCategory] = useState("");
    const [options, setOptions] = useState([]);
    const [showInputField, setShowInputField] = useState(false);


    useEffect(() => {
        setCurrentDate(new Date(initialYear, initialMonth));
    }, [initialYear, initialMonth]);

    const goToPrevious = () => {
        setCurrentDate(prevDate => {
            const prevMonth = new Date(prevDate);
            prevMonth.setMonth(prevMonth.getMonth() - 1);
            return prevMonth;
        });
    };

    const goToNext = () => {
        setCurrentDate(prevDate => {
            const nextMonth = new Date(prevDate);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            return nextMonth;
        });
    };



    const handleDateClick = (day) => {
        if (selectedDates.includes(day)) {
            setSelectedDates(selectedDates.filter(date => date !== day));
        } else {
            setSelectedDates([...selectedDates, day]);
        }
    };


    // dropdown function
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handleAddButtonClick = (e) => {
        e.stopPropagation();
        setShowInputField(true);
    };


    const handleCreateButtonClick = () => {
        if (newCategory.trim() !== "") {
            setOptions([...options, newCategory]);
            setNewCategory("");
            setShowInputField(false);
        }
    };
    const renderMonthHeader = () => {
        return (
            <div className='d-flex'>
                <div>
                    <div className="btn-group me-2" role="group" aria-label="Basic example">
                        <button className='btn btn-primary' onClick={goToPrevious}>
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                        </button>
                        <button className='btn btn-primary' onClick={goToNext}>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>

                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <h2>{currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}</h2>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    <button className='btn btn-primary' onClick={() => setCurrentDate(new Date())}>Today</button>
                </div>
            </div>
        );
    };

    const renderMonthView = () => {
        const numDaysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const startingDay = firstDayOfMonth.getDay(); // Day of the week (0 - 6)
        const daysOfMonth = Array.from({ length: numDaysInMonth }, (_, i) => i + 1);
        const numRows = Math.ceil((startingDay + numDaysInMonth) / 7);

        return (
            <div className="table-responsive">
                <table className="table table-border mt-3 bg-white">
                    <thead>
                        <tr>
                            <th>Sunday</th>
                            <th>Monday</th>
                            <th>Tuesday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(numRows)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {[...Array(7)].map((_, colIndex) => {
                                    const index = rowIndex * 7 + colIndex;
                                    const day = index - startingDay + 1;
                                    const isSelected = selectedDates.includes(day);
                                    const isCurrentMonthDay = index >= startingDay && index < startingDay + numDaysInMonth;

                                    return (
                                        <td key={colIndex} onClick={() => isCurrentMonthDay && handleDateClick(day)}>
                                            {isCurrentMonthDay && (
                                                <>
                                                    <span>{day}</span>
                                                    <div>
                                                        {/* <p className='event--text'>event description</p> */}
                                                    </div>
                                                    {isSelected && (
                                                        <div className="box-cell-grid">
                                                            <h5 style={{ fontSize: '12px' }} >
                                                                {day}
                                                                <span style={{ float: 'right' }} >
                                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                                </span>
                                                            </h5>
                                                            <Link to="/daycalendar" style={{ fontSize: '12px' }} onClick={(e) => e.stopPropagation()}>
                                                                <i class="fa fa-calendar" aria-hidden="true"></i>   View Day
                                                            </Link>
                                                            <Link style={{ fontSize: '12px' }} onClick={(e) => e.stopPropagation()} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <i className="fa fa-briefcase" aria-hidden="true"></i>  Create Event
                                                            </Link>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                    {/* Event modal */}
                    <div>
                        <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header" style={{ backgroundColor: '#4f46e5' }}>
                                        <h5 className="modal-title" id="exampleModalLabel" style={{ color: '#fff' }}>
                                            New Event
                                        </h5>
                                        <button
                                            style={{ backgroundColor: 'none', borderRadius: '5px' }}
                                            type="button"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <label>Customer Name</label>
                                                    <input type='text' className='form-control' placeholder='Customer Name' />
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                    <label>Email</label>
                                                    <input type='email' className='form-control' placeholder='Email' />
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                    <label>Phone Number</label>
                                                    <input type='text' className='form-control' placeholder='Phone Number' />
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                   <label>Event Type</label>
                                                    <AddEventDropdown/>
                                                </div>
                                                <div className='col-md-6 mt-3'>
                                                    <label>Event Date</label>
                                                    <input type='date' className='form-control' placeholder='Phone Number' />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Create Event
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Event modal close */}
                </table>
            </div>
        );
    };

    const handleAction = (day, action) => {
        console.log("Action:", action, "for date:", day);
        // Implement your logic for each action
    };



    return (
        <div>
            {renderMonthHeader()}
            {renderMonthView()}
        </div>
    );
};

export default CalendarGrid;
