import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'; 

// Import Routing Components For Linking to Web pages
import { Link } from "react-router-dom";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
const events = [
    { title: 'Meeting', start: new Date() }
]

function DayCalendar() {
  // State for managing the sidebar's open/close status
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // State for managing the loader
  const [showLoader, setShowLoader] = useState(true);

  // Function that toggles the state controlling the sidebar's open/close status
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Funciton for showing the loader for 1 second only when the page loads
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000); // Adjust the delay time as needed

    return () => clearTimeout(loaderTimeout);
  }, []);

  // The return statement contains the JSX code which is rendered and displayed on the page
  return (
    // Main DIV for the main body & contains IF-Else Javascript that holds the Sidebar-open class
    <div
      className={`position-relative bg-white d-flex p-0 ${
        sidebarOpen ? "sidebar-open" : ""
      }`}
    >
      {/* ============== loader ============== */}
      {showLoader && (
        <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {/* === ***** Sidebar Component w/ Prop ***** The 'sidebarOpen' prop controls whether the Sidebar is open or closed. ===== */}
      <Sidebar sidebarOpen={sidebarOpen} />

      {/* ========= Main Content Area ========= */}
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        {/* Navbar Component with a 'toggleSidebar' prop, which is a function to toggle the sidebar's open/closed state */}
        <Navbar toggleSidebar={toggleSidebar} />

        {/* ================= *****  TEMPLATES BODY JSX ***** ================= */}
        <>
          <div className="container-fluid p-lg-5 p-md-5 p-2">
          <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin]} // Include dayGridPlugin for the "Today" button
                        initialView='timeGridDay'
                        weekends={false}
                        events={events}
                        eventContent={renderEventContent}
                        headerToolbar={{
                            start: 'prev,next today', // Add 'today' for the Today button
                            center: 'title',
                            end: '' // Month and Day buttons
                          }}
                        className="bg-white"
                    />
          </div>
        </>

        {/* ================= *****  TEMPLATES BODY JSX ***** ================= */}
      </div>
    </div>
  );
}

export { DayCalendar }; /* ========================= We had to remove default. */


// a custom render function
function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )
}