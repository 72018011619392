import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { getData, postData } from "../apiHelper";

function Addamountwallet() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [depositRequests, setDepositRequests] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState("approve");
  const [newPayment, setNewPayment] = useState({
    mobile: "",
    amount: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment({ ...newPayment, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!newPayment.mobile || !newPayment.amount) {
      console.error("Mobile number and amount are required.");
      return;
    }

    try {
      const response = await postData("admin/add-amount-to-wallet", newPayment);
      console.log("New payment added:", response);
      // Clear the form after successful submission
      setNewPayment({ mobile: "", amount: "" });
    } catch (error) {
      console.error("Error adding new payment:", error);
    }
  };

  const handleApproved = (item) => {
    setSelectedItem(item);
    openModal();
  };
  const handlerejected = (item) => {
    setSelectedItem(item);
    setAction("rejected");
    openModal();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const approvedRequest = async () => {
    try {
      const data = { unique_id: selectedItem.unique_id, action: action };
      console.log("data", data);
      const response = await postData("admin/approve-deposit-request", data);
      console.log("New payment added:", response);
      // Clear the form after successful submission
    } catch (error) {
      console.error("Error adding new payment:", error);
    }
  };

  const handleDeposit = async () => {
    try {
      const response = await getData("admin/deposit-requests");
      console.log("deposit requests successful!", response);
      setDepositRequests(response.depositRequests);
    } catch (error) {
      console.error("challenges error:", error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    handleDeposit();
    setShowLoader(false);
  }, []);
  return (
    <>
      <div
        className={`position-relative bg-white d-flex p-0 ${
          sidebarOpen ? "sidebar-open" : ""
        }`}
      >
        {showLoader && (
          <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <Sidebar sidebarOpen={sidebarOpen} />
        <div className={`content ${sidebarOpen ? "open" : ""}`}>
          <Navbar toggleSidebar={toggleSidebar} />
          <>
            <div className="container-fluid p-lg-5 p-md-5 p-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Add amount to wallet by user mobile no </h5>
                      <div>
                        <div className="mb-3">
                          <label htmlFor="mobile" className="form-label">
                            Mobile on
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            value={newPayment.mobile}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="amount" className="form-label">
                            Amount
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={newPayment.amount}
                            onChange={handleInputChange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary "
                          onClick={handleSubmit}
                        >
                          Add Payment
                        </button>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <h5>Approved Requist by user </h5>
                      <div>
                        <table className="table mt-5 table-space table-borderless">
                          <thead className="py-2">
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Date</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {depositRequests.map((item) => (
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.amount}</td>
                                <td>
                                  {" "}
                                  {new Date(
                                    item.created_at
                                  ).toLocaleDateString()}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => handleApproved(item)}
                                  >
                                    <i className="fas fa-check"></i> Approved
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger mx-2"
                                    onClick={() => handlerejected(item)}
                                  >
                                    <i className="fas fa-close"></i> Rejected
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#BA1E1E" }}
              >
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ color: "#fff" }}
                >
                  You want to win someone over
                </h5>
                <button
                  style={{
                    backgroundColor: "none",
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                Are you Sure {action} this request ?
              </div>
              <div className="modal-footer d-flex justify-space-between">
                <button
                  type="button"
                  className={action === "approve" ? "btn btn-success":"btn btn-danger"}
                  data-bs-dismiss="modal"
                  onClick={approvedRequest}
                >
                  {action === "approve" ? "Approved ":"Rejected"  }
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Cancelled
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export { Addamountwallet };
