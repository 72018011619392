import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
function Templates() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    return () => clearTimeout(loaderTimeout);
  }, []);
  return (
    <div
      className={`position-relative bg-white d-flex p-0 ${sidebarOpen ? "sidebar-open" : ""
        }`}
    >
      {showLoader && (
        <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Sidebar sidebarOpen={sidebarOpen} />
      <div className={`content ${sidebarOpen ? "open" : ""}`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <>
          <div className="container-fluid p-lg-5 p-md-5 p-3">
            <div className="row">
              <div className='col-lg-12'>
                <table className="table mt-5 table-space table-borderless">
                  <thead className='py-2'>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">CUSTOMERID</th>
                      <th scope="col">NAME</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">MOBILE NO</th>
                      <th scope="col">VOLET</th>
                      <th scope="col">JOIN DATE</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>srg5667</td>
                      <td>monu</td>
                      <td>demo123@gmail.com</td>
                      <td>1234567890</td>
                      <td>₹ 123</td>
                      <td>12/2/24</td>
                      <td> dactive</td>
                      <td>
                        <div>
                          <button type="button" class="btn btn-danger "><i class="fas fa-eye"></i></button>
                          <button type="button" class="btn btn-danger mx-2"><i class="fas fa-trash"></i></button>
                          <button type="button" class="btn btn-danger"><i class="fas fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>srg5667</td>
                      <td>monu</td>
                      <td>demo123@gmail.com</td>
                      <td>1234567890</td>
                      <td>₹ 123</td>
                      <td>12/2/24</td>
                      <td> dactive</td>
                      <td>
                        <div>
                          <button type="button" class="btn btn-danger "><i class="fas fa-eye"></i></button>
                          <button type="button" class="btn btn-danger mx-2"><i class="fas fa-trash"></i></button>
                          <button type="button" class="btn btn-danger"><i class="fas fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export { Templates }; 
