import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import "../App.css";
import { EventOverview } from './EventOverview';
import { EventProducts } from './EventProducts';
import { Payments } from './Payments';

function MichaeMeyers() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalEvent, setshowModalEvent] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const loaderTimeout = setTimeout(() => {
            setShowLoader(false);
        }, 1000);

        return () => clearTimeout(loaderTimeout);
    }, []);

    return (

        <div className={`position-relative bg-white d-flex p-0 ${sidebarOpen ? "sidebar-open" : ""}`} >
            {/* ============== loader ============== */}
            {showLoader && (
                <div className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div
                        className="spinner-border text-primary"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                    >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            {/* === ***** Sidebar Component w/ Prop ***** The 'sidebarOpen' prop controls whether the Sidebar is open or closed. ===== */}
            <Sidebar sidebarOpen={sidebarOpen} />

            {/* ========= Main Content Area ========= */}
            <div className={`content ${sidebarOpen ? "open" : ""}`}>
                {/* Navbar Component with a 'toggleSidebar' prop, which is a function to toggle the sidebar's open/closed state */}
                <Navbar toggleSidebar={toggleSidebar} />

                {/* ================= *****  TEMPLATES BODY JSX ***** ================= */}

                <>
                    <div className="container-fluid p-lg-5 p-md-5 p-2">
                        <div className='row'>
                            <div className='col-lg-10 col-md-10 col-12 mx-auto'>
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link active fs-5" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Event Overview</button>
                                        <button className="nav-link fs-5" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Payment</button>
                                        <button className="nav-link fs-5  border-bottom-5" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Event Products</button>
                                    </div>
                                </nav>
                                <div className="tab-content mt-5" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><EventOverview/></div>
                                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"><Payments/></div>
                                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab"><EventProducts/></div>
                                </div>

                            </div>
                        </div>


                    </div>
                </>


            </div>
        </div>
    );
}

export { MichaeMeyers }; /* ========================= We had to remove default. */
